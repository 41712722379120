<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Intermodal_v2",
  data() {
    this.$i18n.locale = "en";
    return {
      firstRender: true,
      locale: "en",
    };
  },
  updated() {
    if (this.firstRender) {
      this.firstRender = false;

      this.appConfig.configureHttpDefaults(this);
    }
  },
};
</script>

<style lang="css">
@import "./assets/css/reset.css";
@import "./assets/css/lines.css";
</style>

<style lang="scss">
#app,
.tooltip {
  font-family: "Prompt", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  min-width: 1280px;
  min-height: 800px;
}
</style>

<style lang="scss">
@import "@/scss/tooltip.scss";
</style>

<style lang="scss">
$themeColor: #349a51;
@import "~vue-slider-component/lib/theme/default.scss";
.vue-slider-rail {
  height: 1px !important;
}
</style>
