import { config } from "../config.js";

export default {
    install: async (Vue) => {
        // Use dynamic import to load the hooks based on the version
        const preprocessHooksModule = await import(`@/../client-settings/hooks/${config.api.version}/preprocess.ts`);
        const postprocessHooksModule = await import(`@/../client-settings/hooks/${config.api.version}/postprocess.ts`);

        const Hooks = function(app) {
            this.app = app;
            this.hooks = {
                preprocess: preprocessHooksModule.default,
                postprocess: postprocessHooksModule.default,
            };
        };

        Hooks.prototype = {
            preprocessRequest(request, context) {
                // If the hook exists, call it
                if (request.uid && this.hooks.preprocess[request.uid]) {
                    return this.hooks.preprocess[request.uid](request, context);
                } else {
                    return request;
                } // end if
            },
            postprocessResponse(response, context) {
                // If the hook exists, call it
                if (response.uid && this.hooks.postprocess[response.uid]) {
                    return this.hooks.postprocess[response.uid](response, context);
                } else {
                    return response;
                } // end if
            },
        }; // end class

        const hooks = new Hooks(Vue);

        // Attach $hooks to Vue's prototype so it's available globally
        Vue.prototype.$hooks = hooks;
    },
};
