import Vue from 'vue';
import './plugins/fontawesome';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { config } from './config';
import vmodal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import i18n from './i18n';
import VueSlider from 'vue-slider-component';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JsonViewer from 'vue-json-viewer';
import Hooks from './plugins/Hooks.ts';

(async () => {
    Vue.config.productionTip = false;
    Vue.prototype.appConfig = config;

    await Vue.use(Hooks);

    const app = new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount('#app');

    Vue.use(vmodal, { dialog: true });
    Vue.use(VTooltip);
    Vue.use(JsonViewer);
    Vue.component('VueSlider', VueSlider);
    Vue.use(VueAxios, axios);

    Vue.use(Hooks);

    const eventHub = new Vue();

    Vue.mixin({
        data: function () {
            return {
                eventHub: eventHub
            };
        }
    });

    config.configureHttpDefaults(Vue);

    // remove for
    Vue.config.devtools = "development" == process.env.NODE_ENV;

    app.$store.dispatch('initStore', app);
})();