// config.js

let config = {
  version: "2.0.1",
  mapbox: {
    token: process.env.VUE_APP_MAPBOX_TOKEN
  },
  analytics: {
    ga: process.env.VUE_APP_GOOGLE_ANALYTICS_ID
  },
  app: {
    features: [
      "prevSegment",
      "nextSegment",
      "updateRoute",
    ],
  },
  map: {
    plan: {
      color: {
        bikeFriendly: "#74c476",
        fast: "#FF3333",
        generic: "#9A9C9E",
        whiteBackground: "#FFFFFF",
        background: "#666666",
        highlight: "#666666",
        bicycle: "#006E19",
        shared_bike: "#12bad4",
        shared_moped: "#e6cf09",
        shared_car: "#a855d9",
        pt: "#1E26FF",
        walk: "#474747",
        walkUnderground: "#474747",
        walkBikePush: "#006E19",
        car: "#E03BE1",
        taxi: "#7100A6",
        parking: "#053576",
        pickup: "#11ed95",
        transfer: "#cc2812",
      },
      weight: {
        bikeFriendly: 6,
        fast: 6,
        generic: 6,
        whiteBackground: 8,
        background: 8,
        highlight: 16,
        publicTransport: 6,
        geoJson: 7,
        walkUnderground: 6,
        walkBikePush: 6,
      },
      common: {
        opacity: 1,
        lineJoin: 'round',
        lineCap: 'round',
        smoothFactor: 0
      },
      icons: {
        WALK: "walking",
        BICYCLE: "bicycle",
        PT: "bus",
        TAXI: "car",
        CAR: "car",
        SHARED_BIKE: "bicycle",
        SHARED_MOPED: "bolt",
        SHARED_CAR: "car",
      },
      ptIcons: {
        TRAM: "train",
        TRAIN: { src: "./img/icons/train.svg" },
        METRO: { src: "./img/icons/metro.svg" },
        BUS: "bus",
        UNKNOWN: "arrow-right",
      },
      _special: {
        publicTransport: {
          dashArray: "2, 8"
        },
        walkUnderground: {
          dashArray: "2, 8"
        },
        walkBikePush: {
          dashArray: "2, 8"
        },
        arrow: {
          color: {
            bikeFriendly: "#538c54",
            fast: "#bf2222",
            generic: "#a39412"
          },
          pixelSize: 4,
          weight: 2,
        }
      }
    },
    color: {
      providers: {
        rekola: "#C71D70",
        nextbike_td: "#053582",
        nextbike_tg: "#053582",
        nextbike_tq: "#053582",
        nextbike_tk: "#053582",
        berider: "#61D506",
        hoppygo: "#F34D2C",
        car4way: "#17B2D6",
        autonapul: "#4EAA35",
        ajo: "#E7350E",
        anytime: "#D56E24",
        liftago: "#EEB106",
        svezse: "#F22F39",
      },
    },
    segment: {
      color: "#74c476",
      weight: 10,
      opacity: .75,
      lineJoin: 'round',
      lineCap: 'round',
      smoothFactor: 1.5
    },
    styleLayer: process.env.VUE_APP_MAPBOX_STYLE_LAYER,
    tileJson: process.env.VUE_APP_MAP_TILE_JSON_URL,
    vehicleOverlays: ["PT", "BIKESHARING", "MOPEDSHARING", "SCOOTERSHARING", "CARSHARING"]
  },
  api: {
    version: process.env.VUE_APP_API_VERSION,
    keyQueryString: process.env.VUE_APP_API_KEY_QUERY_PARAM,
    authToken: process.env.VUE_APP_X_ACCESS_TOKEN,
    routing: `${process.env.VUE_APP_DATA_SOURCE}/ptre`,
    update: `${process.env.VUE_APP_DATA_SOURCE}/ptre/plannedroute/update`,
    execute: `${process.env.VUE_APP_DATA_SOURCE}/ptre/executeplannedroute`,
    segment: `${process.env.VUE_APP_DATA_SOURCE}/ptre/{direction}`,
    vehicles: {
      pt: `${process.env.VUE_APP_DATA_SOURCE}/ptre/vehiclelocations/publictransport?boundingBox={boundingBox}`,
      bikesharing: `${process.env.VUE_APP_DATA_SOURCE}/ptre/vehiclelocations/freefloating?providerIds={providerIds}&boundingBox={boundingBox}`,
      carsharing: `${process.env.VUE_APP_DATA_SOURCE}/ptre/vehiclelocations/freefloating?providerIds={providerIds}&boundingBox={boundingBox}`,
      mopedsharing: `${process.env.VUE_APP_DATA_SOURCE}/ptre/vehiclelocations/freefloating?providerIds={providerIds}&boundingBox={boundingBox}`,
      scootersharing: `${process.env.VUE_APP_DATA_SOURCE}/ptre/vehiclelocations/freefloating?providerIds={providerIds}&boundingBox={boundingBox}`,
    },
    stations: {
      bikesharing: `${process.env.VUE_APP_DATA_SOURCE}/ptre/stations/bikesharing?providerIds={providerIds}&boundingBox={boundingBox}`,
      pt: `${process.env.VUE_APP_DATA_SOURCE}/ptre/stations/publictransport?boundingBox={boundingBox}`
    },
    settings: `${process.env.VUE_APP_SYSTEM_ORCHESTRATOR_URL}/system/settings`,
    isAlive: `${process.env.VUE_APP_SYSTEM_ORCHESTRATOR_URL}/isalive`
  },
  configureHttpDefaults(app, config) { // NOSONAR // False positive
    app.$http.defaults.params = {};
    
    if (this.api.keyQueryString) {
      app.$http.defaults.params.key = this.api.keyQueryString;
    }

    if (this.api.authToken) {
      const token = this.api.authToken;

      app.$http.interceptors.request.use(function (config) { // NOSONAR // False positive
        config.headers["x-access-token"] = token;
        return config;
      });
    } // end if
  }
}

export { config }
