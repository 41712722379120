import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    map: {
      center: { lat: 50.05448805146173, lng: 14.408736974000933 },
      bbox: { _northEast: { lat: null, lng: null }, _southWest: { lat: null, lng: null } },
      zoom: 13
    },
    panel: {
      mode: "point",
      bike: "road",
      length: 25,
      traffic: "tryAvoidTraffic",
      surface: "prefferPavedSurface",
      hills: "tryAvoidHills",
      poiCnt: 1,
      poiCategories: ["castle", "water", "lookout"],
      origin: {
        mode: "point",
        point: { lat: 50.088188, lng: 14.416677 },
        name: "Start",
        stopIds: undefined
      },
      destination: {
        mode: "point",
        point: { lat: 50.065063, lng: 14.429985 },
        name: "Destination",
        stopIds: undefined
      },
      via: [],
      viaHash: null,
      walk: "walkAverage",
      car: "carAverage",
      cycling: "cycleAverage",
      cyclingBS: "cycleAverage",
      cyclingKms: 5.00,
      cyclingKmsBS: 5.00,
      drivingKms: 15.00,
      carSharingKms: 15.00,
      taxiKms: 15.00,
      mopedSharingKms: 10.00,
      scooterSharingKms: 5.00,
      walkKms: 0.8,
      driving: "driveAverage",
      price: "priceAverage",
      transport: ["WALK", "BICYCLE", "CAR", "PT", "SHARED_BIKE", "SHARED_SCOOTER", "SHARED_CAR", "TAXI"],
      pt: ["BUS", "TRAM", "METRO", "TRAIN", "FERRY", "FUNICULAR", "TROLLEYBUS"],
      bikesharing: [],
      scootersharing: [],
      mopedsharing: [],
      carsharing: [],
      taxisharing: [],
      userFares: [],
      loweredFloorOnly: false,
      barrierFreeStopsOnly: false,
      transfers: 3,
      checkboxes: {
        walking: true,
        cycling: false,
        transportModes: true,
        sharedBikes: false,
        sharedScooters: false,
        sharedMopeds: false,
        sharedCars: false,
        time: true,
        publicTransport: true,
        car: true,
        taxi: false
      },
      time: new Date,
      // 1.5
      stairsFreeWalkSegments: false,
      transferSpeed: "transferAverage",
      pricePerKmInCzk: 5,
      useTollFreeRoads: false,
      parkCar: false,
      parkingZoneTypes: [],
      parkingZoneTypesValues: [],
      parkingProviders: [],
      carParkingTypes: [],
      carParkingTypesValues: [],
      maxParkingPriceCzkPerHour: 100,
      expectedParkingTimeMinutes: 30,
      parkBicycle: true,
      bicycleInPt: false,
      avoidSteepClimbsB: false,
      avoidSteepClimbsBS: false,
      blockedParkingIdsC: [],
      blockedParkingIdsB: [],
      blockedParkingIdsBS: [],
      blockedParkingIdsCS: [],
      returnBestOnlyRouteBS: true,
      returnBestOnlyRouteSS: true,
      returnBestOnlyRouteMS: true,
      returnBestOnlyRouteCS: true,
      returnBestOnlyRouteOD: true,
      onlyElectricBS: false,
      onlyElectricSS: false,
      allowCombustionMS: true,
      allowElectricMS: true,
      allowCombustionCS: true,
      allowElectricCS: true,
      cp: "1",
      ptValidInTrains: true,
      avoidStairsCycling: false,
      avoidStairsWalk: false,
    },
    userSettings: {
      units: "metric"
    },
    plans: [],
    lastResponse: null,
    activePlan: 0,
    planMode: "trajectory",
    autoPlan: true,
    osm: false,
    osmUsable: false,
    operators: null,
    municipalitiesAvailable: false,
    municipalities: { stops: [], municipalities: [] },
    dynamicApi: {
      geocoding: {
        classic: null,
        reverse: null,
      }
    },
    appSettings: {
        allowedVehicleOverlays: ["publicTransport", "sharedBikes", "sharedMopeds", "sharedScooters", "sharedCars"],
        allowedTransportModes: ["publicTransport", "cycling", "walking", "car", "sharedBikes", "sharedScooters", "sharedMopeds", "sharedCars", "taxi"],
    }
  },
  getters: {
    panel: state => {
      return state.panel;
    },
    operators: state => {
      return state.operators;
    },
    mode: state => {
      return state.panel.mode
    },
    bike: state => {
      return state.panel.bike
    },
    length: state => {
      return state.panel.length
    },
    userSettings: state => {
      return state.userSettings
    },
    checkboxes: state => {
      return state.panel.checkboxes
    },
    traffic: state => {
      return state.panel.traffic
    },
    surface: state => {
      return state.panel.surface
    },
    hills: state => {
      return state.panel.hills
    },
    poiCnt: state => {
      return state.panel.poiCnt
    },
    poiCategories: state => {
      return state.panel.poiCategories
    },
    origin: state => {
      return state.panel.origin
    },
    destination: state => {
      return state.panel.destination
    },
    via: state => {
      return state.panel.via
    },
    viaHash: state => {
      return state.panel.viaHash
    },
    mapCenter: state => {
      return state.map.center;
    },
    mapBbox: state => {
      return state.map.bbox;
    },
    mapZoom: state => {
      return state.map.zoom;
    },
    panelOptions: state => {
      return state.panel;
    },
    plans: state => {
      return state.plans;
    },
    lastResponse: state => {
      return state.lastResponse;
    },
    activePlan: state => {
      return state.activePlan;
    },
    planMode: state => {
      return state.planMode;
    },
    walk: state => {
      return state.panel.walk
    },
    driving: state => {
      return state.panel.driving
    },
    cycling: state => {
      return state.panel.cycling
    },
    cyclingBS: state => {
      return state.panel.cyclingBS
    },
    cyclingKms: state => {
      return state.panel.cyclingKms
    },
    cyclingKmsBS: state => {
      return state.panel.cyclingKmsBS
    },
    drivingKms: state => {
      return state.panel.drivingKms
    },
    carSharingKms: state => {
      return state.panel.carSharingKms
    },
    taxiKms: state => {
      return state.panel.taxiKms
    },
    mopedSharingKms: state => {
      return state.panel.mopedSharingKms
    },
    scooterSharingKms: state => {
      return state.panel.scooterSharingKms
    },
    walkKms: state => {
      return state.panel.walkKms
    },
    price: state => {
      return state.panel.price
    },
    transport: state => {
      return state.panel.transport
    },
    pt: state => {
      return state.panel.pt
    },
    userFares: state => {
      return state.panel.userFares
    },
    loweredFloorOnly: state => {
      return state.panel.loweredFloorOnly
    },
    barrierFreeStopsOnly: state => {
      return state.panel.barrierFreeStopsOnly
    },
    transfers: state => {
      return state.panel.transfers
    },
    bikesharing: state => {
      return state.panel.bikesharing
    },
    scootersharing: state => {
      return state.panel.scootersharing
    },
    mopedsharing: state => {
      return state.panel.mopedsharing
    },
    carsharing: state => {
      return state.panel.carsharing
    },
    taxisharing: state => {
      return state.panel.taxisharing
    },
    time: state => {
      return state.panel.time
    },
    autoPlan: state => {
      return state.autoPlan
    },
    osm: state => {
      return state.osm
    },
    osmUsable: state => {
      return state.osmUsable
    },
    // 1.5
    stairsFreeWalkSegments: state => {
      return state.panel.stairsFreeWalkSegments
    },
    transferSpeed: state => {
      return state.panel.transferSpeed
    },
    pricePerKmInCzk: state => {
      return state.panel.pricePerKmInCzk
    },
    useTollFreeRoads: state => {
      return state.panel.useTollFreeRoads
    },
    parkCar: state => {
      return state.panel.parkCar
    },
    parkingProviders: state => {
      return state.panel.parkingProviders
    },
    parkingZoneTypes: state => {
      return state.panel.parkingZoneTypes
    },
    parkingZoneTypesValues: state => {
      return state.panel.parkingZoneTypesValues
    },
    carParkingTypes: state => {
      return state.panel.carParkingTypes
    },
    carParkingTypesValues: state => {
      return state.panel.carParkingTypesValues
    },
    maxParkingPriceCzkPerHour: state => {
      return state.panel.maxParkingPriceCzkPerHour
    },
    expectedParkingTimeMinutes: state => {
      return state.panel.expectedParkingTimeMinutes
    },
    parkBicycle: state => {
      return state.panel.parkBicycle
    },
    bicycleInPt: state => {
      return state.panel.bicycleInPt
    },
    avoidSteepClimbsB: state => {
      return state.panel.avoidSteepClimbsB
    },
    avoidSteepClimbsBS: state => {
      return state.panel.avoidSteepClimbsBS
    },
    blockedParkingIdsC: state => {
      return state.panel.blockedParkingIdsC
    },
    blockedParkingIdsB: state => {
      return state.panel.blockedParkingIdsB
    },
    blockedParkingIdsBS: state => {
      return state.panel.blockedParkingIdsBS
    },
    blockedParkingIdsCS: state => {
      return state.panel.blockedParkingIdsCS
    },
    returnBestOnlyRouteBS: state => {
      return state.panel.returnBestOnlyRouteBS
    },
    returnBestOnlyRouteSS: state => {
      return state.panel.returnBestOnlyRouteSS
    },
    returnBestOnlyRouteMS: state => {
      return state.panel.returnBestOnlyRouteMS
    },
    returnBestOnlyRouteCS: state => {
      return state.panel.returnBestOnlyRouteCS
    },
    returnBestOnlyRouteOD: state => {
      return state.panel.returnBestOnlyRouteOD
    },
    onlyElectricBS: state => {
      return state.panel.onlyElectricBS
    },
    onlyElectricSS: state => {
      return state.panel.onlyElectricSS
    },
    allowCombustionMS: state => {
      return state.panel.allowCombustionMS
    },
    allowElectricMS: state => {
      return state.panel.allowElectricMS
    },
    allowCombustionCS: state => {
      return state.panel.allowCombustionCS
    },
    allowElectricCS: state => {
      return state.panel.allowElectricCS
    },
    cp: state => {
      return state.panel.cp
    },
    ptValidInTrains: state => {
      return state.panel.ptValidInTrains
    },
    municipalitiesAvailable: state => {
      return state.municipalitiesAvailable;
    },
    municipalities: state => {
      return state.municipalities;
    },
    dynamicApi: state => {
      return state.dynamicApi;
    },
    avoidStairsWalk: state => {
      return state.panel.avoidStairsWalk;
    },
    avoidStairsCycling: state => {
      return state.panel.avoidStairsCycling;
    },
    hashTable: state => {
        return state.hashTable;
    },
    appSettings: state => {
        return state.appSettings;
    }
  },
  mutations: {
    panel: (state, panel) => {
      state.panel = panel;
    },
    operators: (state, operators) => {
      state.operators = operators;
    },
    mode: (state, newMode) => {
      state.panel.mode = newMode;
    },
    bike: (state, newBike) => {
      state.panel.bike = newBike;
    },
    length: (state, newLength) => {
      state.panel.length = newLength;
    },
    checkboxes: (state, newCheckboxes) => {
      state.panel.checkboxes = newCheckboxes;
    },
    checkbox: (state, data) => {
      state.panel.checkboxes[data[0]] = data[1];
    },
    traffic: (state, newTraffic) => {
      state.panel.traffic = newTraffic;
    },
    surface: (state, newSurface) => {
      state.panel.surface = newSurface;
    },
    hills: (state, newHills) => {
      state.panel.hills = newHills;
    },
    poiCnt: (state, newPoiCnt) => {
      state.panel.poiCnt = newPoiCnt;
    },
    poiCategories: (state, newCategories) => {
      state.panel.poiCategories = newCategories;
    },
    mapCenter: (state, newCenter) => {
      state.map.center = newCenter;
    },
    mapBbox: (state, newBbox) => {
      state.map.bbox = newBbox;
    },
    mapZoom: (state, newZoom) => {
      state.map.zoom = newZoom;
    },
    plans: (state, plans) => {
      state.plans = plans;
    },
    lastResponse: (state, newResponse) => {
      state.lastResponse = newResponse;
    },
    activePlan: (state, activePlan) => {
      state.activePlan = activePlan;
    },
    planMode: (state, planMode) => {
      state.planMode = planMode;
    },
    origin: (state, data) => {
      state.panel.origin = data;
    },
    destination: (state, data) => {
      state.panel.destination = data;
    },
    via: (state, data) => {
      state.panel.via = data;
    },
    waypoint: (state, data) => {
      state.panel.via.splice(data.index, 1, data.waypoint);
    },
    viaHash: (state, data) => {
      state.panel.viaHash = data;
    },
    walk: (state, newWalk) => {
      state.panel.walk = newWalk;
    },
    driving: (state, newDriving) => {
      state.panel.driving = newDriving;
    },
    cycling: (state, newCycling) => {
      state.panel.cycling = newCycling;
    },
    cyclingBS: (state, newValue) => {
      state.panel.cyclingBS = newValue;
    },
    cyclingKms: (state, newCyclingKms) => {
      state.panel.cyclingKms = newCyclingKms;
    },
    cyclingKmsBS: (state, newValue) => {
      state.panel.cyclingKmsBS = newValue;
    },
    drivingKms: (state, newDrivingKms) => {
      state.panel.drivingKms = newDrivingKms;
    },
    carSharingKms: (state, newValue) => {
      state.panel.carSharingKms = newValue;
    },
    taxiKms: (state, newValue) => {
      state.panel.taxiKms = newValue;
    },
    mopedSharingKms: (state, newValue) => {
      state.panel.mopedSharingKms = newValue;
    },
    scooterSharingKms: (state, newValue) => {
      state.panel.scooterSharingKms = newValue;
    },
    walkKms: (state, newWalkKms) => {
      state.panel.walkKms = newWalkKms;
    },
    price: (state, newPrice) => {
      state.panel.price = newPrice;
    },
    transport: (state, newTransport) => {
      state.panel.transport = newTransport;
    },
    pt: (state, newPt) => {
      state.panel.pt = newPt;
    },
    bikesharing: (state, newBikesharing) => {
      state.panel.bikesharing = newBikesharing;
    },
    scootersharing: (state, newScootersharing) => {
      state.panel.scootersharing = newScootersharing;
    },
    mopedsharing: (state, newMopedsharing) => {
      state.panel.mopedsharing = newMopedsharing;
    },
    userFares: (state, newUserFares) => {
      state.panel.userFares = newUserFares;
    },
    loweredFloorOnly: (state, newLoweredFloorOnly) => {
      state.panel.loweredFloorOnly = newLoweredFloorOnly;
    },
    barrierFreeStopsOnly: (state, newValue) => {
      state.panel.barrierFreeStopsOnly = newValue;
    },
    transfers: (state, newTransfers) => {
      state.panel.transfers = newTransfers;
    },
    carsharing: (state, newCarsharing) => {
      state.panel.carsharing = newCarsharing;
    },
    taxisharing: (state, newTaxisharing) => {
      state.panel.taxisharing = newTaxisharing;
    },
    time: (state, newTime) => {
      state.panel.time = newTime;
    },
    autoPlan: (state, newAutoPlan) => {
      state.autoPlan = newAutoPlan;
    },
    osm: (state, newOsm) => {
      state.osm = newOsm;
    },
    osmUsable: (state, newOsmUsable) => {
      state.osmUsable = newOsmUsable;
    },
    // 1.5
    stairsFreeWalkSegments: (state, newValue) => {
      state.panel.stairsFreeWalkSegments = newValue;
    },
    transferSpeed: (state, newValue) => {
      state.panel.transferSpeed = newValue;
    },
    pricePerKmInCzk: (state, newValue) => {
      state.panel.pricePerKmInCzk = newValue;
    },
    useTollFreeRoads: (state, newValue) => {
      state.panel.useTollFreeRoads = newValue;
    },
    parkCar: (state, newValue) => {
      state.panel.parkCar = newValue;
    },
    parkingProviders: (state, newValue) => {
      state.panel.parkingProviders = newValue;
    },
    parkingZoneTypes: (state, newValue) => {
      state.panel.parkingZoneTypes = newValue;
    },
    parkingZoneTypesValues: (state, newValue) => {
      state.panel.parkingZoneTypesValues = newValue;
    },
    carParkingTypes: (state, newValue) => {
      state.panel.carParkingTypes = newValue;
    },
    carParkingTypesValues: (state, newValue) => {
      state.panel.carParkingTypesValues = newValue;
    },
    maxParkingPriceCzkPerHour: (state, newValue) => {
      state.panel.maxParkingPriceCzkPerHour = newValue;
    },
    expectedParkingTimeMinutes: (state, newValue) => {
      state.panel.expectedParkingTimeMinutes = newValue;
    },
    parkBicycle: (state, newValue) => {
      state.panel.parkBicycle = newValue;
    },
    bicycleInPt: (state, newValue) => {
      state.panel.bicycleInPt = newValue;
    },
    avoidSteepClimbsB: (state, newValue) => {
      state.panel.avoidSteepClimbsB = newValue;
    },
    avoidSteepClimbsBS: (state, newValue) => {
      state.panel.avoidSteepClimbsBS = newValue;
    },
    blockedParkingIdsC: (state, newValue) => {
      state.panel.blockedParkingIdsC = newValue.filter(val => { return val != ""; });
    },
    blockedParkingIdsB: (state, newValue) => {
      state.panel.blockedParkingIdsB = newValue.filter(val => { return val != ""; });
    },
    blockedParkingIdsBS: (state, newValue) => {
      state.panel.blockedParkingIdsBS = newValue.filter(val => { return val != ""; });
    },
    blockedParkingIdsCS: (state, newValue) => {
      state.panel.blockedParkingIdsCS = newValue.filter(val => { return val != ""; });
    },
    returnBestOnlyRouteBS: (state, newValue) => {
      state.panel.returnBestOnlyRouteBS = newValue;
    },
    returnBestOnlyRouteSS: (state, newValue) => {
      state.panel.returnBestOnlyRouteSS = newValue;
    },
    returnBestOnlyRouteMS: (state, newValue) => {
      state.panel.returnBestOnlyRouteMS = newValue;
    },
    returnBestOnlyRouteCS: (state, newValue) => {
      state.panel.returnBestOnlyRouteCS = newValue;
    },
    returnBestOnlyRouteOD: (state, newValue) => {
      state.panel.returnBestOnlyRouteOD = newValue;
    },
    onlyElectricBS: (state, newValue) => {
      state.panel.onlyElectricBS = newValue;
    },
    onlyElectricSS: (state, newValue) => {
      state.panel.onlyElectricSS = newValue;
    },
    allowCombustionMS: (state, newValue) => {
      state.panel.allowCombustionMS = newValue;
    },
    allowElectricMS: (state, newValue) => {
      state.panel.allowElectricMS = newValue;
    },
    allowCombustionCS: (state, newValue) => {
      state.panel.allowCombustionCS = newValue;
    },
    allowElectricCS: (state, newValue) => {
      state.panel.allowElectricCS = newValue;
    },
    cp: (state, newValue) => {
      state.panel.cp = newValue;
    },
    ptValidInTrains: (state, newValue) => {
      state.panel.ptValidInTrains = newValue;
    },
    municipalitiesAvailable: (state, newValue) => {
      state.municipalitiesAvailable = newValue;
    },
    municipalities: (state, newValue) => {
      state.municipalities = newValue;
    },
    dynamicApi: (state, newValue) => {
      state.dynamicApi = newValue;
    },
    avoidStairsWalk: (state, newValue) => {
        state.panel.avoidStairsWalk = newValue;
    },
    avoidStairsCycling: (state, newValue) => {
        state.panel.avoidStairsCycling = newValue;
    },
  },
  actions: {
    initStore: (state, app) => {
      let key = `ar-state-${app.appConfig.version}`;
      const blob = window.localStorage.getItem(key);

      if (blob !== null && blob.length > 100) {
        try {
          const panel = JSON.parse(blob);
          panel.time = new Date(panel.time);
          state.commit("panel", panel);
        } catch (error) {
          console.log(`Exception: Could not parse ${key} from localStorage`);
        } // end try-catch
      } // end if

      app.$http.get(`${process.env.VUE_APP_SYSTEM_ORCHESTRATOR_URL}/system/webapp/settings`, {
        headers: {
          "content-type": "application/json; charset=utf-8",
        }
      }).then(result => {
        let dt = new Date();
        let staticFilesHeaders = {
          "content-type": "application/json; charset=utf-8",
          "Cache-Control": "max-age=86400",
          "Expires": (new Date(dt.setMonth(dt.getMonth() + 1))).toISOString(),
        };

        const settingsCrossroad = result.data;

        state.commit("dynamicApi", {
          geocoding: {
            classic: settingsCrossroad.geocodingApiUrl,
            reverse: settingsCrossroad.reverseGeocodingApiUrl
          }
        });


        const mobilityOperatorsJsonPromise = app.$http.get(settingsCrossroad.mobilityOperatorsUrl, {
          headers: staticFilesHeaders
        });
       
        const stopsPromise = app.$http.get(settingsCrossroad.ptStopsUrl, {
          headers: staticFilesHeaders
        });

        const municipalitiesPromise = app.$http.get(settingsCrossroad.municipalitiesUrl, {
          headers: staticFilesHeaders
        });

        app.$http.all([mobilityOperatorsJsonPromise]).then(res => {
          const operators = {
            shared_bike: [],
            shared_scooter: [],
            shared_moped: [],
            shared_car: [],
            taxi: [],
            parking: [],
            all: []
          };

          res[0].data.forEach(item => {
            if (item.transportModes.includes("SHARED_BIKE")) {
              operators.shared_bike[item.id] = item;
            }
            if (item.transportModes.includes("SHARED_SCOOTER")) {
              operators.shared_scooter[item.id] = item;
            }
            if (item.transportModes.includes("SHARED_MOPED")) {
              operators.shared_moped[item.id] = item;
            }
            if (item.transportModes.includes("SHARED_CAR")) {
              operators.shared_car[item.id] = item;
            }
            if (item.transportModes.includes("TAXI")) {
              operators.taxi[item.id] = item;
            }
            if (true === item.isParkingProvider) {
              operators.parking[item.id] = item;
            }

            operators.all[item.id] = item;
          });

          state.commit("operators", operators);

          state.commit("bikesharing", Object.keys(operators.shared_bike));
          state.commit("scootersharing", Object.keys(operators.shared_scooter));
          state.commit("mopedsharing", Object.keys(operators.shared_moped));
          state.commit("carsharing", Object.keys(operators.shared_car));
          state.commit("taxisharing", Object.keys(operators.taxi));
          state.commit("parkingProviders", Object.keys(operators.parking));

        });

        app.$http.all([stopsPromise, municipalitiesPromise]).then(res => {
          state.commit("municipalities", {
            stops: res[0].data.stopGroups,
            municipalities: res[1].data.features
          });
          state.commit("municipalitiesAvailable", true);
        }).catch(ex => {

        });

      });



      app.$http.get(`${process.env.VUE_APP_SYSTEM_ORCHESTRATOR_URL}/system/settings`, null, {
        headers: {
          "content-type": "application/json; charset=utf-8",
        }
      }).then(res => {
        let allCarParkingTypes = [];
        let parkingZoneTypes = [];

        for (const param of res.data.parameters) {
          if ("PARKING_TYPE_ALLOWED" == param.id) {
            allCarParkingTypes = param.maxValue;
          } // end if
          if ("PARKING_ZONE_TYPE_ALLOWED" == param.id) {
            parkingZoneTypes = param.maxValue;
          } // end if
        } // end for

        state.commit("carParkingTypesValues", JSON.parse(JSON.stringify(allCarParkingTypes)));
        state.commit("parkingZoneTypesValues", JSON.parse(JSON.stringify(parkingZoneTypes)));

        if (state.getters.carParkingTypes.length == 0) {
          state.commit("carParkingTypes", JSON.parse(JSON.stringify(allCarParkingTypes)));
        } // end if
        if (state.getters.parkingZoneTypes.length == 0) {
          state.commit("parkingZoneTypes", JSON.parse(JSON.stringify(parkingZoneTypes)));
        } // end if

      });

    },
    serialize: (state, version) => {
      let key = `ar-state-${version}`;

      const cache = [];
      const blob = JSON.stringify(
        state.getters.panel,
        (_key, value) => {
          if (typeof value === "object" && value !== null) {
            // Duplicate reference found, discard key
            if (cache.includes(value)) return;

            // Store value in our collection
            cache.push(value);
          }
          return value;
        },
        2
      );

      window.localStorage.setItem(key, blob);
    }
  },
  modules: {
  }
})
